<template>
  <main-layout flex>
    <div class="minigame-page">
      <div class="minigame-page__playground">
        <iframe :src="gameSrc" frameborder="0" />
      </div>
    </div>
  </main-layout>
</template>

<script>
export default {
  name: 'MinigamePage',
  data() {
    return {
      gameSrc: '',
    };
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
  async created() {
    try {
      const response = await api.get(
        `v2/mini-games/tournaments/${this.tournamentId}/play`,
      );
      this.gameSrc = response.data.playMiniGameUrl;
    } catch (error) {
      this.$router.push(
        `/minigames/tournaments/${this.tournamentId}`,
      );
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.minigame-page {
  width: 100%;
}
.minigame-page__playground {
  height: 100%;

  iframe {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
